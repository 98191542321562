// 模版片段

const list = [
  {
    name: '联系方式',
    key: 'contact',
    content: `
# 刘德华 - Web 前端开发工程师

（HR会打印你的简历，用于在面试的时候联系，所以联系方式放到最上边会比较方便）

- 手机：135.... （如果是外地手机，可注明。如经常关机，要写上最优联系时间）
- Email：goodman@gmail.com （虽然我觉得QQ邮箱无所谓，不过有些技术人员比较反感，建议用G，非要用的话，不要用数字邮箱名）
- QQ/微信号：6...（提供一个通过网络可以联系到你的方式）
\n`
  },
  {
    name: '个人信息',
    key: 'profile',
    content: `\n
    
# 个人信息

- 刘德华/男/1994 
- 本科/北极大学计算机系 
- 工作年限：3年
- 技术博客：https://goover.top ( 使用GitHub Host的Big较高  )
- Github：https://github.com/md-cv ( 有原创repo的Github帐号会极大的提升你的个人品牌  )

- 期望职位：Web高级前端开发工程师
- 期望薪资：税前月薪15k~20k，特别喜欢的公司可例外
- 期望城市：北京

\n
    `
  },
  {
    name: '技能清单',
    key: 'skill',
    content: `\n
# 技能清单
（我一般主张将技能清单写入到工作经历里边去。不过很难完整，所以有这么一段也不错）

以下均为我熟练使用的技能

- Web开发：PHP/Hack/Node
- Web框架：ThinkPHP/Yaf/Yii/Lavarel/LazyPHP
- 前端框架：Vue/React/Angular/Bootstrap/AngularJS/EmberJS/HTML5/Cocos2dJS/ionic
- 前端工具：Webpack/Bower/Gulp/SaSS/LeSS/PhoneGap
- 数据库相关：MySQL/PgSQL/PDO/SQLite
- 版本管理、文档和自动化部署工具：Svn/Git/PHPDoc/Phing/Composer/Travis/GitLab
- 单元测试：PHPUnit/SimpleTest/Qunit/
- 云和开放平台：SAE/BAE/AWS/微博开放平台/微信应用开发/小程序
    
    \n`
  },
  {
    name: '工作经历',
    key: 'experience',
    content: `\n
# 工作经历
（工作经历按逆序排列，最新的在最前边，按公司做一级分组，公司内按二级分组）

## ABC公司 （ 2012年9月 ~ 2014年9月 ）

### DEF项目 
我在此项目负责了哪些工作，分别在哪些地方做得出色/和别人不一样/成长快，这个项目中，我最困难的问题是什么，我采取了什么措施，最后结果如何。这个项目中，我最自豪的技术细节是什么，为什么，实施前和实施后的数据对比如何，同事和领导对此的反应如何。


### GHI项目 
我在此项目负责了哪些工作，分别在哪些地方做得出色/和别人不一样/成长快，这个项目中，我最困难的问题是什么，我采取了什么措施，最后结果如何。这个项目中，我最自豪的技术细节是什么，为什么，实施前和实施后的数据对比如何，同事和领导对此的反应如何。
### 其他项目

（每个公司写2~3个核心项目就好了，如果你有非常大量的项目，那么按分类进行合并，每一类选一个典型写出来。其他的一笔带过即可。）    
\n`
  },
  {
    name: '开源项目与作品',
    key: 'project',
    content: `\n
    
# 开源项目和作品
## 技术文章
（这一段用于放置工作以外的、可证明你的能力的材料）

（挑选你写作或翻译的技术文章，好的文章可以从侧面证实你的表达和沟通能力，也帮助招聘方更了解你）

- [一个产品经理眼中的云计算：前生今世和未来](http://get.jobdeer.com/706.get)
- [来自HeroKu的HTTP API 设计指南(翻译文章)](http://get.jobdeer.com/343.get) （ 好的翻译文章可以侧证你对英文技术文档的阅读能力）

## 演讲和讲义
（放置你代表公司在一些技术会议上做过的演讲，以及你在公司分享时制作的讲义）

- 2014架构师大会演讲：[如何通过Docker优化内部开发](http://ftqq.com)
- 9月公司内部分享：[云计算的前生今世](http://ftqq.com)
    
## 开源作品
（对于程序员来讲，没有什么比Show me the code能有说服力了）
- [STU](http://github.com/yourname/projectname)：项目的简要说明，Star和Fork数多的可以注明
- [WXYZ](http://github.com/yourname/projectname)：项目的简要说明，Star和Fork数多的可以注明
    \n`
  },
  {
    name: '致谢',
    key: 'thank',
    content: `\n
 
---      
# 致谢
感谢您花时间阅读我的简历，期待能有机会和您共事。
    \n`
  }
];

export default list;

export function getTemplat() {
  return list.map(({ content }) => content).join('');
}
