export const DOCUMENT = {
  CREATE: 'DOCUMENT_CREATE',
  DELETE: 'DOCUMENT_DELETE',
  UPDATE: 'DOCUMENT_UPDATE',
  SET_CURRENT: 'DOCUMENT_SET_CURRENT',
  INIT: 'DOCUMENT_INIT',
  APPEND: 'DOCUMENT_APPEND'
};

export const CONFIG = {
  UPDATE_NAV: 'CONFIG_UPDATE_NAV'
};
